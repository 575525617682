@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

@font-face {
  font-family: 'Lora';
  src: url('assets/fonts/Lora-Regular.ttf');
}

@font-face {
  font-family: 'LoraBold';
  src: url('assets/fonts/Lora-Bold.ttf');
}
@font-face {
  font-family: 'LoraSemibold';
  src: url('assets/fonts/Lora-SemiBold.ttf');
}
@font-face {
  font-family: 'LoraSemiboldIt';
  src: url('assets/fonts/Lora-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'LoraMedium';
  src: url('assets/fonts/Lora-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-VariableFont_wght.ttf');
  font-weight: 100 900;
}
@font-face {
  font-family: 'MontserratMedium';
  src: url('assets/fonts/Montserrat-Medium.ttf');
}


@font-face {
  font-family: 'MarcellusSC';
  src: url('assets/fonts/MarcellusSC-Regular.ttf');
}

@layer components {
  .image-gradient-transparent::before {
    content: '';
    position: absolute;
    width: inherit;
    height: 20%;
    z-index: -1;
    background-image: url('assets/images/waterTexture.jpg');
    background-size: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  .reflection {
    position: relative;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 50px 70px rgba(0, 0, 0, 0.3), 0px 10px 10px rgba(0, 0, 0, 0.2);
  }

  .reflection::after {
    pointer-events: none;
    content: '';
    background-image: inherit;
    width: inherit;
    height: 100%;
    position: absolute;
    bottom: -100%;
    transform: scaleY(-1);
    background-position: center;
    background-size: cover;
    opacity: 0.2;
    mask-image: linear-gradient(transparent 80%, white 100%);
    z-index: 2;
  }

  .reflection-rounded {
    border-radius: 12px;
    position: relative;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 50px 70px rgba(0, 0, 0, 0.3), 0px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .reflection-rounded::after {
    border-radius: 12px;
    pointer-events: none;
    content: '';
    background-image: inherit;
    width: inherit;
    height: 100%;
    position: absolute;
    bottom: -100%;
    transform: scaleY(-1);
    background-position: center;
    background-size: cover;
    opacity: 0.2;
    mask-image: linear-gradient(transparent 85%, white 90%);
    z-index: 2;
  }
}

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite-desktop::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite-mobile::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (537 / 995));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: #f00;
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}